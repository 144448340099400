'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import IcCaretDown from '../../icons/icons/Navigation/CaretDown';
import { cltw } from '../../utils';

/**
 * @description Accordion implementation from Shadcn
 * @documentation Shadcn wrapper around radix https://ui.shadcn.com/docs/components/accordion
 * @see https://www.radix-ui.com/primitives/docs/components/accordion
 */
const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item className={cltw(className)} ref={ref} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

/**
 * @description Basic Accordion Trigger with no icon
 * @documentation https://www.radix-ui.com/primitives/docs/components/accordion#item
 */
const BasicAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className='flex'>
    <AccordionPrimitive.Trigger
      className={cltw(
        'flex flex-1 items-center justify-between py-2 font-medium transition-all',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
BasicAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

/**
 * @description Accordion Trigger with default Chevron down icon.
 * @documentation https://www.radix-ui.com/primitives/docs/components/accordion#trigger
 */
const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    isCaretLeft?: boolean;
  }
>(({ children, className, isCaretLeft = false, ...props }, ref) => (
  <BasicAccordionTrigger
    ref={ref}
    {...props}
    className={cltw(
      'hover:underline [&[data-state=open]>svg]:rotate-180',
      className,
    )}
  >
    {isCaretLeft ? <IcCaretDown fill='black' height={14} /> : null}
    <div className={cltw(!isCaretLeft ? 'w-11/12' : '')}>{children}</div>
    {!isCaretLeft ? <IcCaretDown fill='black' height={14} /> : null}
  </BasicAccordionTrigger>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

/**
 * @documentation https://www.radix-ui.com/primitives/docs/components/accordion#content
 */
const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    className='data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm'
    ref={ref}
    {...props}
  >
    <div className={cltw('pb-3', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

/**
 * @description Custom Accordion Trigger that takes in the icon prop
 */
const CustomAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    icon?: React.ReactNode;
    atStart?: boolean;
  }
>(({ children, icon, atStart = false, ...props }, ref) => (
  <BasicAccordionTrigger ref={ref} {...props}>
    {atStart ? icon : null}
    {children}
    {atStart ? null : icon}
  </BasicAccordionTrigger>
));
CustomAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  BasicAccordionTrigger,
  CustomAccordionTrigger,
};
