'use client';

import React, { useEffect, useState } from 'react';
import {
  AmplitudeEvents,
  AmplitudeProperties,
} from 'whoop-analytics/lib/types';
import { UUID_COOKIE_NAME } from 'whoop-analytics/lib/sources/experiment';
import { whoopAnalyticsScrollTracker } from 'whoop-analytics/lib/sources/whoop-analytics';
import { useShallow } from 'zustand/react/shallow';
import type { JoinFlowContent } from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import { setCookie } from 'whoop-analytics/utils';
import { getMembership } from 'ui';
import { usePathname } from 'next/navigation';
import { useStripe } from '@stripe/react-stripe-js';
import { getOneTouchDigitalWalletOptions } from 'ui/utils/paymentHelpers';
import { isRestOfWorld } from '@whoop/i18n';
import { createClientAuth } from '@whoop/auth-client';
import { useAnalytics } from '@/hooks/analytics';
import { useSiteStore } from '@/store/SiteStoreProvider';
import type {
  IInitialSiteData,
  PromoType,
} from '@/lib/utils/initialDataFetcher';
import {
  SKIP_MEM_SELECT,
  type SkipMemVariantOptions,
  JF_SHIPPING_OPTIONS,
  JF_BNPL_V2,
  JFRP_EX_NZ_AU,
} from '@/lib/constants/experiments';
import {
  MEMBERSHIP_OPTIONS_EXPERIMENT,
  JF_EXPRESS_CHECKOUT,
} from '@/lib/constants/experiments';
import { useExperimentService } from '@/hooks/experimentService';
import { env } from 'env';
import { getSkipMemSelectProperty } from '@/lib/utils/analyticsHelper';
import { getHasFamilyMemberships } from '@/lib/utils/familyHelpers';
import type { SiteWidePromoContent } from '../../types/siteWidePromoTypes';
import LandingPageContent from './LandingPageContent/LandingPageContent';

interface LandingPageProps {
  authToken: string;
  featureFlags?: Record<string, boolean>;
  isMobile?: boolean;
  initialData: IInitialSiteData;
  promoContent?: JoinFlowContent;
  promo?: CheckoutPromo;
  sitewidePromoContent?: SiteWidePromoContent;
  experimentUUID?: string;
  promoType: PromoType;
  skipMemVariant?: SkipMemVariantOptions;
  skipMemSelectionParams?: {
    membership: string;
    location: string;
    size?: string;
  };
  shippingOptionsVariant?: string;
}
export default function LandingPage({
  featureFlags,
  isMobile,
  initialData,
  promo,
  promoContent,
  promoType,
  sitewidePromoContent,
  experimentUUID,
  skipMemVariant,
  skipMemSelectionParams,
  shippingOptionsVariant,
}: LandingPageProps): JSX.Element {
  const auth = createClientAuth({
    env: env.NEXT_PUBLIC_ENV,
    segmentKey: env.NEXT_PUBLIC_SEGMENT_API_KEY,
  });

  const {
    currency,
    region,
    initializeFeatureFlags,
    initializePromo,
    setRequiredProducts,
    setAccessories,
    setInitialTotals,
    setProductNodes,
    setWhoopYourWayConfigs,
    setReturnShippingCost,
    setIsRafGiftFlow,
    setPromoType,
    setExperimentVariant,
    setStripePaymentRequest,
  } = useSiteStore(
    useShallow((state) => ({
      currency: state.currency,
      region: state.region,
      initializeFeatureFlags: state.initializeFeatureFlags,
      initializePromo: state.initializePromo,
      setRequiredProducts: state.setRequiredProducts,
      setAccessories: state.setAccessories,
      setInitialTotals: state.setInitialTotals,
      setProductNodes: state.setProductNodes,
      setWhoopYourWayConfigs: state.setWhoopYourWayConfigs,
      setReturnShippingCost: state.setReturnShippingCost,
      setIsRafGiftFlow: state.setIsRafGiftFlow,
      setPromoType: state.setPromoType,
      setExperimentVariant: state.setExperimentVariant,
      setStripePaymentRequest: state.setStripePaymentRequest,
    })),
  );
  const { getVariant } = useExperimentService();
  const pathname = usePathname();
  const stripe = useStripe();
  const {
    initialize,
    isInitialized,
    trackPageView,
    trackAmplitudeEvent,
    setAmplitudeUserProperty,
  } = useAnalytics();

  useEffect(() => {
    if (isInitialized) {
      if (skipMemVariant && skipMemSelectionParams) {
        setExperimentVariant(SKIP_MEM_SELECT, skipMemVariant);
        // We don't need to set the experiment for trials since its on for all now
        if (skipMemSelectionParams.membership !== 'trial') {
          setAmplitudeUserProperty('skip-membership-select', skipMemVariant);
          setAmplitudeUserProperty(
            'skip-membership-select-membership',
            skipMemSelectionParams.membership,
          );
          if (skipMemSelectionParams.size) {
            setAmplitudeUserProperty(
              'skip-membership-select-size',
              skipMemSelectionParams.size,
            );
          }
          setAmplitudeUserProperty(
            'skip-membership-select-location',
            skipMemSelectionParams.location,
          );
        }
      } else {
        // If the user is not in the experiment, set the Amplitude property to another value to denote this
        setAmplitudeUserProperty('skip-membership-select', 'null');
        setAmplitudeUserProperty('skip-membership-select-membership', 'null');
        setAmplitudeUserProperty('skip-membership-select-location', 'null');
      }

      if (!isRestOfWorld(currency, region)) {
        if (shippingOptionsVariant) {
          setExperimentVariant(JF_SHIPPING_OPTIONS, shippingOptionsVariant);
          setAmplitudeUserProperty(JF_SHIPPING_OPTIONS, shippingOptionsVariant);
        }
      }

      // Only running the BNPL experiment in the US for now
      if (region === 'US') {
        getVariant(JF_BNPL_V2);
      }

      const { skip_mem_select, family_size } = getSkipMemSelectProperty(
        skipMemSelectionParams?.membership,
        skipMemVariant,
        skipMemSelectionParams?.size,
      );

      const hasFamilyMemberships = getHasFamilyMemberships(
        initialData.membershipData,
      );
      const label = hasFamilyMemberships
        ? AmplitudeProperties.FamilyMembershipVisible
        : undefined;

      trackAmplitudeEvent(AmplitudeEvents.ViewedJoinFlow, {
        skip_mem_select,
        family_size,
        label,
      });
      setAmplitudeUserProperty(JFRP_EX_NZ_AU, 'control');

      if (['AU', 'NZ'].includes(region)) {
        trackAmplitudeEvent(AmplitudeEvents.ViewedJoinFlowRollout);
      }
    }

    // Don't have trackAmplitudeEvent as a dependency because otherwise this useEffect runs more than it need to
  }, [isInitialized]);

  const [loading, setLoading] = useState(true);

  const initializeStripePaymentOptions = async () => {
    if (!stripe) return;

    const options = getOneTouchDigitalWalletOptions({
      initialTotals: initialData.initialTotals,
      currency,
      cartProducts: [],
      promoCode: promo?.promo_code,
      promoInfo: promo,
      stripeCountry: '',
      pendingOrder: undefined,
      shippingOption: undefined,
    });

    const request = stripe.paymentRequest({
      ...options,
      // This is for the region that our stripe account is setup in. Not the users country. Don't change this.
      country: 'US',
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: true,
    });

    const paymentRequestOptions = await request.canMakePayment();
    if (!paymentRequestOptions) {
      setAmplitudeUserProperty('express-checkout-available', 'false');
      setAmplitudeUserProperty(JF_EXPRESS_CHECKOUT, 'none');
      return;
    }

    setStripePaymentRequest({
      request,
      paymentOptionsAvailable: paymentRequestOptions,
    });
  };

  function init(): void {
    if (initialData.membershipData) {
      setRequiredProducts(initialData.membershipData);
      setAccessories(initialData.accessoriesData);
      setInitialTotals(initialData.initialTotals);
      setProductNodes(initialData.productNodes);
      setWhoopYourWayConfigs(initialData.whoopYourWayConfigs);
      setReturnShippingCost(initialData.returnShippingCost);
    }

    setPromoType(promoType);
    // If a token exists, it will be validated when the user gets to the gift page in middleware
    // This is to ensure we don't show gift copy to users on the landing page
    const isRafGift = Boolean(auth.getToken()) && pathname.includes('/gift/');
    setIsRafGiftFlow(isRafGift);
    initializeFeatureFlags(featureFlags ?? {});
    initializePromo(promoContent, promo, sitewidePromoContent, isRafGift);

    initialize();
    trackPageView('Landing');
  }

  function initScroll(): void {
    document.addEventListener('scroll', () => {
      whoopAnalyticsScrollTracker(trackAmplitudeEvent);
    });
  }

  useEffect(() => {
    // If making a server side call to get experiment UUID, we need to set the cookie here
    // This way that same uuid is used for all experiment calls made throughout the app
    if (experimentUUID) {
      setCookie(UUID_COOKIE_NAME, experimentUUID);
    }
    init();
    initScroll();

    const monthlyMembership = getMembership(
      'monthly',
      initialData.membershipData?.join_memberships,
    );
    if (monthlyMembership) getVariant(MEMBERSHIP_OPTIONS_EXPERIMENT);

    setLoading(false);

    return () => {
      document.removeEventListener('scroll', () => {
        whoopAnalyticsScrollTracker(trackAmplitudeEvent);
      });
    };
  }, []);

  useEffect(() => {
    initializeStripePaymentOptions();
  }, [stripe]);

  const skipMemSelection =
    skipMemVariant && skipMemVariant !== 'control'
      ? skipMemSelectionParams?.membership
      : undefined;
  const skipMemFamilySize =
    skipMemSelection === 'family' && skipMemSelectionParams?.size
      ? skipMemSelectionParams?.size
      : null;

  return (
    <div className='bg-white'>
      <LandingPageContent
        accessories={initialData.accessoriesData}
        isMobile={isMobile}
        loading={loading}
        promoContent={promoContent}
        requiredProducts={initialData.membershipData}
        skipMemFamilySize={skipMemFamilySize}
        skipMemSelection={skipMemSelection}
        whoopYourWayConfigs={initialData.whoopYourWayConfigs}
      />
    </div>
  );
}
