import Image from 'next/image';

export default function ImageWithFallback({
  alt,
  className = '',
  fallback,
  height,
  priority = false,
  src,
  setSrc,
  width,
}): React.JSX.Element {
  return (
    <Image
      alt={alt}
      className={className}
      height={height}
      onError={() => {
        setSrc(fallback);
      }}
      priority={priority}
      src={src}
      width={width}
    />
  );
}
