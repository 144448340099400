import { useTranslation } from '@whoop/i18n/lang/client';
import { Button } from 'ui/components/ButtonV2/Button';
import { useFormatter } from '@/hooks/formatter';

interface LandingPageBottomCTAProps {
  onClick: () => void;
  showTrialsDisclaimer?: boolean;
}

function LandingPageBottomCTA({
  onClick,
  showTrialsDisclaimer = true,
}: LandingPageBottomCTAProps): JSX.Element {
  const { formatDisplayPrice } = useFormatter();
  const { t } = useTranslation('joinLandingPage');

  return (
    <section className='bg-black px-4 py-20 sm:px-8'>
      <div className='mx-auto flex max-w-screen-md flex-col items-center gap-10 text-center'>
        <h3 className='text-3xl text-white'>
          {showTrialsDisclaimer
            ? t('footerHeading', {
                price: formatDisplayPrice(0),
              })
            : t('noTrialsFooterHeader')}
        </h3>
        <div className='flex flex-col items-center gap-24'>
          <div className='w-full max-w-2xl px-2 sm:px-10'>
            <Button onClick={onClick} size='medium' variant='secondaryBright'>
              {t('footerCTA')}
            </Button>
          </div>
          <p className='max-w-2xl px-4 text-sm text-white sm:px-16'>
            {t('footerParagraph')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default LandingPageBottomCTA;
