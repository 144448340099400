'use client';

import { useReportWebVitals } from 'next/web-vitals';
import { useRef, useCallback } from 'react';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { useAnalytics } from '@/hooks/analytics';

export function WebVitals(): null {
  const { trackAmplitudeEvent } = useAnalytics();
  const calledMetrics = useRef(new Set<string>());

  const handleMetric = useCallback(
    (metric) => {
      if (!calledMetrics.current.has(metric.name)) {
        calledMetrics.current.add(metric.name);
        switch (metric.name) {
          case 'FCP': {
            trackAmplitudeEvent(AmplitudeEvents.WebVitals, {
              name: 'FCP',
              value: metric.value,
            });
            break;
          }
          case 'LCP': {
            trackAmplitudeEvent(AmplitudeEvents.WebVitals, {
              name: 'LCP',
              value: metric.value,
            });
            break;
          }
          case 'TTFB': {
            trackAmplitudeEvent(AmplitudeEvents.WebVitals, {
              name: 'TTFB',
              value: metric.value,
            });
            break;
          }
          default:
            break;
        }
      }
    },
    [trackAmplitudeEvent],
  );

  useReportWebVitals(handleMetric);

  return null;
}
