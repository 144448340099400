import type { IconProps } from '../../../components/Icon/Icon.util';

export const Add = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path d='M15.5596 4C15.0073 4 14.5596 4.44772 14.5596 5V14.56H5C4.44772 14.56 4 15.0077 4 15.56V16.56C4 17.1123 4.44772 17.56 5 17.56H14.5596V27C14.5596 27.5523 15.0073 28 15.5596 28H16.5596C17.1119 28 17.5596 27.5523 17.5596 27V17.56H27C27.5523 17.56 28 17.1123 28 16.56V15.56C28 15.0077 27.5523 14.56 27 14.56H17.5596V5C17.5596 4.44772 17.1119 4 16.5596 4H15.5596Z' />
    </svg>
  );
};

export default Add;
