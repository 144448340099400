import type { IconProps } from '../../../components/Icon/Icon.util';

export const IcArrowRight = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path d='M29.5678 14.9223C30.1441 15.5175 30.1441 16.4824 29.5678 17.0775L18.3116 28.7022C17.9274 29.099 17.3045 29.099 16.9203 28.7022L16.1457 27.9022C15.7615 27.5055 15.7615 26.8622 16.1457 26.4654L24.7792 17.5493L3.00002 17.5493C2.44774 17.5493 2.00002 17.1016 2.00002 16.5493V15.4514C2.00002 14.8991 2.44774 14.4514 3.00002 14.4514L24.7798 14.4514L16.1457 5.53459C15.7615 5.13783 15.7615 4.49456 16.1457 4.09779L16.9203 3.29782C17.3045 2.90105 17.9274 2.90105 18.3116 3.29782L29.5678 14.9223Z' />
    </svg>
  );
};

export default IcArrowRight;
