import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Button, UnstyledButton } from '../ButtonV2/Button';
import IcArrowRight from '../../icons/icons/Navigation/ArrowRight';
import Add from '../../icons/icons/Actions/Add';

interface ICTACard {
  title: string;
  subtitles: string[];
  CTATestId?: string;
  onClick: () => void;
  iconSrc?: string;
  children?: React.ReactElement;
  actionIconType?: 'arrowRight' | 'add';
  bgColor?: string;
  className?: string;
}

function CTACard({
  title,
  subtitles,
  CTATestId = 'cta-card',
  onClick,
  iconSrc,
  children,
  actionIconType = 'arrowRight',
  bgColor = 'bg-white',
  className,
}: ICTACard): React.ReactNode {
  const ActionIcon = actionIconType === 'arrowRight' ? IcArrowRight : Add;
  const hoverStyles =
    'hover:shadow-lg hover:outline-black-a300 hover:outline hover:outline-2 hover:border-white';
  return (
    <UnstyledButton
      className={clsx(
        'font-whoop border-gray-20 relative flex w-full cursor-pointer border',
        'flex-row items-start justify-between rounded-3xl border text-left',
        'gap-3 px-5 pb-5 pt-5 text-black shadow-sm',
        bgColor,
        hoverStyles,
        className,
      )}
      data-testid={CTATestId}
      onClick={onClick}
      type='button'
    >
      {iconSrc ? (
        <div className=''>
          <Image
            alt='decorative icon'
            height={45}
            role='presentation'
            src={iconSrc}
            width={45}
          />
        </div>
      ) : null}
      <div className='flex flex-col'>
        <div>
          <div className='text-[20px] font-bold leading-6'>
            <h3>{title}</h3>
          </div>
        </div>
        <div className='pt-1'>
          <div>
            {subtitles?.map((subtitle) => (
              <p
                className='pt-2 text-xs leading-4 text-black/50'
                key={subtitle}
              >
                {subtitle}
              </p>
            ))}
            <div className='relative -top-1'>{children}</div>
          </div>
        </div>
      </div>
      <div className='relative -top-2 pl-1'>
        <div className='max-h-10 rounded-full border-black bg-black p-2.5 text-sm text-white disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400 dark:border-white'>
          <ActionIcon
            fill='white'
            height={16}
            title='Select Membership'
            width={16}
          />
        </div>
      </div>
    </UnstyledButton>
  );
}

export default CTACard;
