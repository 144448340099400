import Image from 'next/image';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  CustomAccordionTrigger,
} from 'ui/components/AccordionV3/Accordion';
import React, { useState } from 'react';
import Remove from 'ui/icons/icons/Actions/Remove';
import Add from 'ui/icons/icons/Actions/Add';
import { cltw } from 'ui';

interface IHowItWorksCard {
  title: string;
  subtitle: string;
  iconUrl: string;
  accordionTitle: string;
  accordionText: React.ReactNode;
  isJfHeroCarouselEnabled: boolean;
}

function HowItWorksCard({
  title,
  subtitle,
  iconUrl,
  accordionTitle,
  accordionText,
  isJfHeroCarouselEnabled,
}: IHowItWorksCard): JSX.Element {
  const [accordionActiveValue, setAccordionActiveValue] = useState<string>();
  const accordionItemValue = `how-it-works-${title}`;

  return (
    <li
      className={cltw(
        'text-gray-60 z-10 grow-0 rounded-3xl p-7 md:w-1/4',
        isJfHeroCarouselEnabled ? 'bg-white' : 'bg-gray-100',
      )}
    >
      <div className='flex flex-row content-start justify-between pb-4'>
        <p className='text-accent experiment-uppercase pb-3 text-sm font-bold'>
          {subtitle}
        </p>
        <Image
          alt='decorative icon'
          height={32}
          role='presentation'
          src={iconUrl}
          width={32}
        />
      </div>
      <h4 className='pb-4 text-2xl text-black lg:min-h-[80px]'>{title}</h4>
      <Accordion
        collapsible
        onValueChange={setAccordionActiveValue}
        type='single'
        value={accordionActiveValue}
      >
        <AccordionItem value={accordionItemValue}>
          <CustomAccordionTrigger
            atStart
            className='flex justify-start p-0'
            icon={
              <div className='icon-container mr-4 w-4 lg:w-8'>
                {accordionActiveValue === accordionItemValue ? (
                  <Remove fill='black' title='Close' />
                ) : (
                  <Add fill='black' title='Open' />
                )}
              </div>
            }
          >
            <h3 className='text-start text-base font-normal text-gray-500'>
              {accordionTitle}
            </h3>
          </CustomAccordionTrigger>
          <AccordionContent className='p-4 pb-8 text-base font-normal'>
            {accordionText}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </li>
  );
}

export default HowItWorksCard;
