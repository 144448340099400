import type { URLSearchParams } from 'node:url';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import { i18n } from '@whoop/i18n/lang/client';
import type {
  ApplicationScheme,
  JoinFlowContent,
} from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import {
  getMembershipTypeApplicationSchemeForCurrency,
  getPromoDisclaimer,
  getReferrer,
} from 'ui';
import { formatDisplayPrice } from 'lib/utils/formatHelpers';

export const getLandingPageSubtextCopy = ({
  promoContent,
  promoInfo,
  currency,
  hasTrialMembership,
  language,
  region,
  isRafGiftFlow = false,
  skipMemSelection = '',
  searchParams,
}: {
  promoContent?: JoinFlowContent;
  promoInfo?: CheckoutPromo;
  currency: Currency;
  hasTrialMembership: boolean;
  language: Language;
  region: Region;
  isRafGiftFlow?: boolean;
  skipMemSelection?: string;
  searchParams?: URLSearchParams;
}): {
  mobileSubtext: string;
  desktopSubtext: string;
  imageSrc?: string;
} => {
  const familySkipMembershipEligible =
    searchParams?.get('membership') === 'family' &&
    Boolean(searchParams?.get('size'));

  if (skipMemSelection) {
    let membershipText = '';
    if (skipMemSelection === 'annual') {
      membershipText = i18n.t('joinLandingPage:annualMembership');
    } else if (skipMemSelection === '24month') {
      membershipText = i18n.t('joinLandingPage:24MonthMembership');
    } else if (skipMemSelection === 'trial') {
      membershipText = i18n.t('joinLandingPage:priceToBegin', {
        priceToBegin: formatDisplayPrice(0, {
          currency,
          language,
          region,
        }),
      });
    } else if (skipMemSelection === 'family') {
      const size = searchParams?.get('size');
      membershipText = i18n.t('joinLandingPage:familyMembership', { size });
    }
    const subtext = `${membershipText} | ${i18n.t(
      'joinLandingPage:whoop4Included',
    )}`;
    return {
      imageSrc: undefined,
      desktopSubtext: subtext,
      mobileSubtext: subtext,
    };
  }
  // If partnerships/rafGift promo content has been populated, use that.
  // Eventually, that will contain the default text and RAF/Affiliate text as well
  if (
    promoContent &&
    (promoContent.partnership_name || isRafGiftFlow) &&
    !familySkipMembershipEligible
  ) {
    return {
      imageSrc: promoContent.content.image,
      desktopSubtext: promoContent.content.base_desktop_subtext,
      mobileSubtext: promoContent.content.base_mobile_subtext,
    };
  }

  const referrer = getReferrer(promoInfo);
  if (referrer && promoInfo) {
    const validPromo = getMembershipTypeApplicationSchemeForCurrency(
      promoInfo.promo_code.application_schemes as ApplicationScheme[],
      currency,
    );
    const referrerText = getPromoDisclaimer({
      promo: validPromo,
      location: 'primary',
      referrer,
      currency,
      language,
      region,
    });
    return {
      imageSrc: referrer.avatar_url,
      desktopSubtext: referrerText,
      mobileSubtext: referrerText,
    };
  }

  const desktopSubtext = hasTrialMembership
    ? i18n.t('joinLandingPage:joinWhoopForAsLowAs', {
        priceToBegin: formatDisplayPrice(0, { currency, language, region }),
      })
    : i18n.t('joinLandingPage:joinWhoopNoTrial');

  const priceToBegin = hasTrialMembership
    ? `${i18n.t('joinLandingPage:priceToBegin', {
        priceToBegin: `${formatDisplayPrice(0, {
          currency,
          language,
          region,
        })}`,
      })} | `
    : '';

  return {
    imageSrc: undefined,
    desktopSubtext,
    mobileSubtext: `${priceToBegin} ${i18n.t(
      'joinLandingPage:whoop4Included',
    )}`,
  };
};
