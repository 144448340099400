import type { CartProduct, MembershipProduct } from 'ui';
import { getMembership, ProductType } from 'ui';
import { findCartProductByType } from './cartManager';

export const membershipQueryParamOptions = [
  'annual',
  '24month',
  'trial',
  'family',
];

export const getMembershipFromQueryParam = (
  membershipSearchParam: string,
  membershipsAvailable: MembershipProduct[],
  skipMemFamilySize?: string | null,
): MembershipProduct | undefined => {
  // If proper skip mem select query param is present
  if (!membershipQueryParamOptions.includes(membershipSearchParam)) {
    return undefined;
  }

  // Validate that the membership is available for the experiment
  // Example : ROW flows don’t have trials, so exclude them from the experiment if they come from a trials membership link
  if (membershipSearchParam === 'trial') {
    return getMembership('trial', membershipsAvailable);
  }
  if (membershipSearchParam === 'annual') {
    return getMembership('prepaid', membershipsAvailable, 12);
  }
  if (membershipSearchParam === '24month') {
    return getMembership('prepaid', membershipsAvailable, 24);
  }
  if (membershipSearchParam === 'family' && skipMemFamilySize) {
    return membershipsAvailable.find(
      (membership) =>
        membership.membership_type === 'family' &&
        membership.family_size === parseInt(skipMemFamilySize, 10),
    );
  }
  return undefined;
};

// Get the membership from the cart, then convert that into the skip membership select query param membership value
export const getQueryParamFromMembership = (
  cartProducts: CartProduct[],
): string => {
  const membership = findCartProductByType(
    cartProducts,
    ProductType.MEMBERSHIP,
  );
  const membershipItem = membership?.item as MembershipProduct;
  if (membershipItem?.membership_type === 'prepaid') {
    if (membershipItem.trial_months === 12) {
      return 'annual';
    }
    return '24month';
  } else if (membershipItem?.membership_type === 'trial') {
    return 'trial';
  }
  return '';
};
