import type { IconProps } from '../../../components/Icon/Icon.util';

export const Remove = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path d='M4 15.5C4 14.9477 4.44772 14.5 5 14.5H27C27.5523 14.5 28 14.9477 28 15.5V16.5C28 17.0523 27.5523 17.5 27 17.5H5C4.44772 17.5 4 17.0523 4 16.5V15.5Z' />
    </svg>
  );
};

export default Remove;
