'use client';

import type { MouseEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { CarouselApi } from 'ui/components/Carousel/Carousel';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'ui/components/Carousel/Carousel';
import { Progress } from 'ui/components/Progress/Progress';
import { cltw } from 'ui';
import Image from 'next/image';

import LayeredImage from 'ui/components/LayeredImage/LayeredImage';
import type { HeroCarouselItem } from '@/client/contentfulClient';
import contentfulClient from '@/client/contentfulClient';
import type { PersonalizationOption } from '@/lib/utils/personalizationHelper';
import { useAnalytics } from '@/hooks/analytics';
import { AmplitudeActions, AmplitudeEvents } from 'whoop-analytics/lib/types';

export interface HeroCarouselProps
  extends React.HTMLAttributes<HTMLDivElement> {
  hoverOption?: PersonalizationOption;
  wywImages: string[];
  showCustomWywImage?: boolean;
  region?: string;
  language?: string;
  className?: string;
  isMobile?: boolean;
  isJfHeroCarouselEnabled?: boolean;
}

export function HeroCarousel({
  hoverOption,
  children,
  wywImages,
  showCustomWywImage,
  region,
  language,
  isMobile,
  isJfHeroCarouselEnabled,
  className,
}: HeroCarouselProps): JSX.Element {
  const { trackAmplitudeEvent } = useAnalytics();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [content, setContent] = useState<HeroCarouselItem[]>([]);
  // Used to track the current index that the carousel is on
  const [progress, setProgress] = useState(0);
  // Used to track whether or not the carousel is at an index that maps to content or a hard coded carousel item
  const [contentIndex, setContentIndex] = useState<
    HeroCarouselItem | undefined
  >();

  const [numberOfSlidesSeen, setNumberOfSlidesSeen] = useState(
    Array(content.length + 2).fill(0),
  );

  const getCarouselContent = async () => {
    const items = await contentfulClient.getHeroCarouselContent({
      language,
      region,
    });

    if (!items) return;
    const content = items.region?.items?.length
      ? items.region.items
      : items?.default?.items;
    setContent(content[0].itemsCollection.items ?? []);
  };

  useEffect(() => {
    if (isJfHeroCarouselEnabled && content.length === 0) {
      getCarouselContent();
    }
  }, [isJfHeroCarouselEnabled]);

  useEffect(() => {
    if (!isJfHeroCarouselEnabled) return;
    if (carouselApi && progress > carouselApi?.previousScrollSnap()) {
      let imageTitle = content[progress]?.caption;
      imageTitle ??= progress === content.length ? 'last' : 'first';
      trackAmplitudeEvent(AmplitudeEvents.HeroCarousel, {
        action: AmplitudeActions.HeroCarouselActionIncrement,
        index: progress,
        total: numberOfSlidesSeen.filter((slide) => slide === 1).length,
        imageTitle,
      });
    } else {
      trackAmplitudeEvent(AmplitudeEvents.HeroCarousel, {
        action: AmplitudeActions.HeroCarouselActionDecrement,
        index: carouselApi?.selectedScrollSnap(),
      });
    }
    /**
     *  Because the length of content is different to number of carousel elements
     *  we need keep track of if we are on a content index or not also allows us to fade in/out
     */
    const hasSeen = numberOfSlidesSeen[progress] === 1;
    if (!hasSeen) {
      setNumberOfSlidesSeen((prev) => {
        prev[progress] = 1;
        return prev;
      });
    }

    if (progress === 0) {
      setContentIndex(undefined);
      return;
    }

    setContentIndex(content[progress - 1]);
  }, [progress]);

  useEffect(() => {
    carouselApi?.on('scroll', () => {
      setProgress(carouselApi?.selectedScrollSnap());
    });
  }, [carouselApi]);

  useEffect(() => {
    // When the user hovers over a swatch, scroll to the first slide
    if (
      !isJfHeroCarouselEnabled ||
      (hoverOption?.handle && hoverOption?.handle === 'onyx') ||
      carouselApi?.selectedScrollSnap() === 0 ||
      carouselApi?.selectedScrollSnap() === content.length + 1
    ) {
      return;
    }

    carouselApi?.scrollTo(0);
    trackAmplitudeEvent(AmplitudeEvents.HeroCarousel, {
      action: AmplitudeActions.HeroCarouselActionHoverScroll,
      index: 0,
    });
  }, [hoverOption]);

  const increment = (event: MouseEvent) => {
    event.stopPropagation();
    if (progress === content.length + 1) {
      carouselApi?.scrollTo(0);
    } else {
      carouselApi?.scrollNext();
    }
  };

  const decrement = (event: MouseEvent) => {
    event.stopPropagation();
    if (carouselApi?.selectedScrollSnap() === 0) return;
    carouselApi?.scrollPrev();
  };

  if (!isJfHeroCarouselEnabled) {
    return children as JSX.Element;
  }

  return (
    <div className={cltw('w-full md:w-[95%] md:max-w-[700px]', className)}>
      <Carousel
        className={cltw(
          'relative cursor-pointer rounded-2xl bg-gray-50 md:aspect-square',
        )}
        opts={{
          align: 'start',
          loop: true,
        }}
        setApi={setCarouselApi}
      >
        {/* Secondary Image */}
        {content.map((item) => (
          <Image
            alt='Product'
            className={cltw(
              'duration-400 absolute left-[30px] top-[45%] z-10 h-[70%] w-auto -translate-y-[50%] opacity-0 transition-all md:-left-[35px] md:top-[50px] md:h-[75%] md:translate-y-0',
              contentIndex?.secondaryImage?.url === item.secondaryImage?.url
                ? 'opacity-100'
                : 'opacity-0',
            )}
            height={700}
            key={item.secondaryImage?.url}
            onError={(event) => (event.currentTarget.style.display = 'none')}
            src={item.secondaryImage?.url ?? ''}
            width={700}
          />
        ))}
        <CarouselContent>
          {/* Primary Images */}
          <CarouselItem
            className='select-none sm:rounded-2xl'
            key={1}
            onClick={increment}
          >
            {children}
          </CarouselItem>
          {content.map((item, index) => (
            <CarouselItem
              className='relative select-none rounded-2xl'
              key={item.caption}
              onClick={increment}
            >
              <div className='relative sm:rounded-2xl'>
                <Image
                  alt='Product'
                  className='aspect-square rounded-2xl object-contain'
                  height={700}
                  key={item?.backgroundImage?.url}
                  src={item?.backgroundImage?.url}
                  width={700}
                />
                <p
                  className={cltw(
                    'absolute bottom-8 left-[4.5%] text-base font-semibold md:bottom-8 md:text-lg',
                    item.captionColor === 'white' ? 'text-white' : 'text-black',
                  )}
                >
                  {item.caption}
                </p>
              </div>
            </CarouselItem>
          ))}
          {/* Last Band Image */}
          <CarouselItem
            className='flex select-none items-center justify-center rounded-2xl'
            key={content.length + 2}
            onClick={(event) => increment(event)}
          >
            {showCustomWywImage ? (
              <div className='aspect-square w-[75%]'>
                <LayeredImage images={wywImages} />
              </div>
            ) : (
              <Image
                alt='Product'
                className='aspect-square w-[75%] object-contain'
                height={700}
                src={hoverOption?.image ?? ''}
                width={700}
              />
            )}
          </CarouselItem>
        </CarouselContent>
        <div className='absolute bottom-3 right-[4.5%] flex justify-end gap-x-4 md:bottom-1'>
          <CarouselPrevious
            className='h-8 w-8 rounded-full bg-white opacity-100 transition duration-300 disabled:opacity-50 md:h-10 md:w-10'
            disabled={carouselApi?.selectedScrollSnap() === 0}
            iconSize={isMobile ? 14 : 18}
            onClick={(event) => decrement(event)}
          />
          <CarouselNext
            className='h-8 w-8 rounded-full bg-white md:h-10 md:w-10'
            iconSize={isMobile ? 14 : 18}
            onClick={(event) => increment(event)}
          />
        </div>
        <div
          className={cltw(
            'absolute bottom-3 left-1/2 flex  w-[90%] -translate-x-1/2 items-stretch md:bottom-3 md:w-11/12',
            content.length ? '' : 'hidden',
          )}
        >
          {Array(content.length + 2)
            .fill(0)
            .map((_, i) => {
              return (
                <Progress
                  className={cltw(
                    'h-[4px] flex-grow rounded-none bg-gray-200',
                    i === 0
                      ? 'rounded-l-lg'
                      : i === content.length + 1
                      ? 'rounded-r-lg'
                      : '',
                  )}
                  value={i === progress ? 100 : 0}
                />
              );
            })}
        </div>
      </Carousel>
    </div>
  );
}
export default HeroCarousel;
