'use client';

import React, { useMemo } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useShallow } from 'zustand/react/shallow';
import type { MembershipProduct } from 'services/generated/growth-content-service';
import { getMembership } from 'ui';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useFormatter } from '@/hooks/formatter';
import { SKIP_MEM_SELECT } from '@/lib/constants/experiments';

const headerClassNames =
  'mx-0 text-4xl font-semibold experiment-uppercase tracking-tighter sm:text-5xl md:pb-3 lg:text-6xl';

export default function LandingPageHeader(): React.JSX.Element {
  const {
    language,
    requiredProducts,
    promoInfo,
    siteWidePromoContent,
    isRafGiftFlow,
    experimentVariants,
  } = useSiteStore(
    useShallow((state) => ({
      language: state.language,
      requiredProducts: state.requiredProducts,
      promoInfo: state.promoInfo,
      siteWidePromoContent: state.siteWidePromoContent,
      isRafGiftFlow: state.isRafGiftFlow,
      experimentVariants: state.experimentVariants,
    })),
  );

  const { formatDisplayPrice } = useFormatter();
  const { t } = useTranslation('joinLandingPage', language);

  const monthlyMembership: MembershipProduct | undefined = useMemo(
    () => getMembership('monthly', requiredProducts.join_memberships),
    [requiredProducts],
  );
  if (isRafGiftFlow) {
    return <h2 className={headerClassNames}>{t('orderPage:giftWhoop')}</h2>;
  }

  if (
    experimentVariants[SKIP_MEM_SELECT] &&
    experimentVariants[SKIP_MEM_SELECT] !== 'control'
  ) {
    return <h2 className={headerClassNames}>{t('chooseABand')}</h2>;
  }

  return (
    <h2 className={headerClassNames}>
      {(promoInfo?.referral || promoInfo?.affiliate) &&
      monthlyMembership &&
      !siteWidePromoContent ? (
        <>
          <div className='hidden text-4xl md:block'>
            <span>{t('joinWhoopFor')}</span>
            <span className='line-through opacity-20'>
              {formatDisplayPrice(monthlyMembership.display_price)}
            </span>
            <span>{` ${formatDisplayPrice(0)}`}</span>
          </div>
          <div className='block text-4xl md:hidden'>
            <span>{`${t('joinWhoopFor')} ${formatDisplayPrice(0)}`}</span>
          </div>
        </>
      ) : (promoInfo?.referral || promoInfo?.affiliate) &&
        !siteWidePromoContent ? (
        <span>{`${t('joinWhoopFor')} ${formatDisplayPrice(0)}`}</span>
      ) : (
        t('joinWhoop')
      )}
    </h2>
  );
}
