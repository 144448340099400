import Image from 'next/image';
import { useTranslation } from '@whoop/i18n/lang/client';
import { cltw } from 'ui';
import HowItWorksCard from './howItWorksCard';

function HowItWorks({
  isJfHeroCarouselEnabled,
}: {
  isJfHeroCarouselEnabled: boolean;
}): JSX.Element {
  const { t } = useTranslation('joinLandingPage');

  const cardInfo = [
    {
      title: t('howItWorks.card1.title'),
      subtitle: t('howItWorks.card1.subtitle'),
      iconUrl: '/landingPage/duration.svg',
      accordionTitle: t('howItWorks.card1.accordionTitle'),
      accordionText: <p>{t('howItWorks.card1.accordionText')}</p>,
    },
    {
      title: t('howItWorks.card2.title'),
      subtitle: t('howItWorks.card2.subtitle'),
      iconUrl: '/landingPage/strap-gray.svg',
      accordionTitle: t('howItWorks.card2.accordionTitle'),
      accordionText: <p>{t('howItWorks.card2.accordionText')}</p>,
    },
    {
      title: t('howItWorks.card3.title'),
      subtitle: t('howItWorks.card3.subtitle'),
      iconUrl: '/landingPage/advice.svg',
      accordionTitle: t('howItWorks.card3.accordionTitle'),
      accordionText: <p>{t('howItWorks.card3.accordionText')}</p>,
    },
    {
      title: t('howItWorks.card4.title'),
      subtitle: t('howItWorks.card4.subtitle'),
      iconUrl: '/landingPage/send.svg',
      accordionTitle: t('howItWorks.card4.accordionTitle'),
      accordionText: <p>{t('howItWorks.card4.accordionText')}</p>,
    },
  ];

  return (
    <section
      className={cltw(
        'how-it-works pb-12 pt-14 lg:pb-14 lg:pt-14',
        isJfHeroCarouselEnabled ? 'bg-gray-50' : 'bg-white',
      )}
    >
      <div className='container mx-auto px-6'>
        <h3 className='text-bold font-whoop text-center text-5xl'>
          {t('howItWorksTitle')}
        </h3>
        <div className='how-it-works--steps'>
          <ul className='py-2xl relative mx-auto flex list-none flex-col gap-6 after:absolute after:left-1/2 after:top-[100px] after:h-2/3 after:w-1/2 after:border-l-2 after:border-dashed after:border-black after:content-[""] md:max-w-[1372px] md:flex-row md:items-start md:justify-center md:after:left-[1%] md:after:top-[160px] md:after:w-full md:after:max-w-[99%] md:after:border-l-0 md:after:border-t-2 lg:py-16'>
            {cardInfo.map(
              ({ title, subtitle, iconUrl, accordionTitle, accordionText }) => (
                <HowItWorksCard
                  accordionText={accordionText}
                  accordionTitle={accordionTitle}
                  iconUrl={iconUrl}
                  key={title}
                  subtitle={subtitle}
                  title={title}
                  isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
                />
              ),
            )}
          </ul>
        </div>
        <div className='lifetime-warranty mx-auto max-w-[1000px] text-center'>
          <Image
            alt='Strap'
            className='text-red mx-auto'
            height={32}
            role='presentation'
            src='/landingPage/strap.svg'
            width={32}
          />
          {/* These need to be translated */}
          <h3 className='text-bold font-whoop py-5 text-4xl'>
            {t('lifetimeWarranty')}
          </h3>
          <p className='text-black/50'>{t('designedToBeWorn')} </p>
          <p className='text-black/50'>
            {t('disclaimer')}
            <a
              className='experiment-underline text-accent'
              href='https://whoop.com/termsofuse'
              rel='noopener'
              target='_blank'
            >
              {t('futherTsAndCs')}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
