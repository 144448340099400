import { cltw } from '../../utils';
import Skeleton from '../Skeleton/Skeleton';

function SkeletonWrap({ className }) {
  return (
    <Skeleton className={cltw('rounded-xl', className)} variant='secondary' />
  );
}

export default function LandingPageLoadingState(): JSX.Element {
  return (
    <section className='grid h-full min-h-[calc(100vh-180px)] grid-cols-1 bg-gray-50 md:grid-cols-2 lg:grid-cols-2'>
      <div className='hidden h-full w-full items-center justify-end pl-14 pr-4 md:flex'>
        <SkeletonWrap className='h-[600px] w-[600px]' />
      </div>
      <div className='m-auto flex w-full max-w-[480px] flex-col justify-center space-y-5 p-5 px-4 py-5 text-center sm:pt-5 md:m-0 md:py-12 md:pl-16 md:text-left'>
        <div className='flex flex-col items-center gap-2 md:hidden'>
          <SkeletonWrap className='h-[40px] w-2/3 ' />
          <SkeletonWrap className='h-[40px] w-2/3 ' />
        </div>
        <SkeletonWrap className='flex aspect-square w-full md:hidden' />
        <SkeletonWrap className='hidden h-[50px] w-full md:flex' />
        <SkeletonWrap className='hidden h-[80px] w-full  md:flex' />
        <SkeletonWrap className='hidden h-7 w-full  md:flex' />
        <SkeletonWrap className='h-7 w-full' />
        <div className='grid w-full max-w-lg grid-cols-6 gap-3'>
          {Array.from({ length: 20 }).map((_, index) => (
            <SkeletonWrap
              className='h-[40px] w-[40px] rounded-full'
              key={index}
            />
          ))}
        </div>
        <SkeletonWrap className='h-7 w-full' />
        <div className='flex flex-row gap-3'>
          <SkeletonWrap className='h-7 w-full' />
          <SkeletonWrap className='h-7 w-full' />
        </div>
        <SkeletonWrap className='h-7 w-full' />
      </div>
    </section>
  );
}
