import { env } from 'env';

export const featureFlagNames = {
  SITE_BANNER: 'join-flow-site-banner',
  CARD_ON_FILE: 'jf-raf-gift-card-on-file',
  FAMILY_PLANS: 'join-flow-fp',
  FLIPKART_REDIRECT: 'redirect-india-flipkart',
  USE_INR_RENEWAL_PRICE: 'jf-inr-renewal-price',
  DISABLE_IL_JF: 'il-disabled-on-JF',
};

export const getAreFeatureFlagsOnForAll = async (
  flags: string[],
): Promise<Record<string, boolean>> => {
  const promises = flags.map(async (flagName: string) => {
    try {
      const response = await fetch(
        `${env.NEXT_PUBLIC_WHOOP_API}/feature-flags/flags/${flagName}/onforall`,
        {
          method: 'GET',
          headers: {
            // Fetch needs an accept language, otherwise it returns a 500
            // https://github.com/denoland/deno/issues/10987
            'Accept-Language': 'en',
          },
          next: { revalidate: 0 },
        },
      );
      if (response.ok) {
        return response.json();
      }
      return false;
    } catch (_err) {
      // False is the default FF state if anything fails
      return false;
    }
  });

  const results = await Promise.allSettled(promises);
  const parsedFlags: Record<string, boolean> = {};
  results.forEach((result, index) => {
    if (result.status === 'fulfilled') {
      parsedFlags[flags[index]] = result.value;
    } else {
      parsedFlags[flags[index]] = false;
    }
  });

  return parsedFlags;
};
