'use client';

import { useMemo } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useShallow } from 'zustand/react/shallow';
import { type AccessoryProduct } from 'ui/types';
import type { AccessoryGroup } from 'services/generated/growth-content-service';
import {
  canAddEngraving,
  getEngravingGroup,
  hasAddedEngraving,
} from 'lib/utils/engravingHelper';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useFormatter } from '@/hooks/formatter';

interface EngravingSectionProps {
  accessories: AccessoryGroup[];
  showEngraving: () => void;
}

export default function EngravingSection({
  showEngraving,
  accessories,
}: EngravingSectionProps): JSX.Element | null {
  const { formatDisplayPrice } = useFormatter();
  const { t } = useTranslation('joinLandingPage');
  const { cartProducts } = useSiteStore(
    useShallow((state) => ({
      cartProducts: state.cartProducts,
    })),
  );

  const { canAdd, hasAdded } = useMemo(() => {
    return {
      canAdd: canAddEngraving(cartProducts),
      hasAdded: hasAddedEngraving(cartProducts),
    };
  }, [cartProducts]);

  const {
    engravingGroup,
    engravingProduct,
  }: { engravingGroup?: AccessoryGroup; engravingProduct?: AccessoryProduct } =
    useMemo(() => {
      const group = getEngravingGroup(accessories);
      if (!group) return {};
      return {
        engravingGroup: group,
        engravingProduct: group.products.length ? group.products[0] : undefined,
      };
    }, [accessories]);

  if (!canAdd || !engravingGroup || !engravingProduct) return null;

  return (
    <button
      className='sm:p-sm flex w-full cursor-pointer items-center border-none bg-transparent p-2 hover:opacity-70 md:p-4'
      onClick={showEngraving}
      type='button'
    >
      <div>
        <p className='text-accent text-body-xs experiment-uppercase experiment-bold experiment-underline mb-0 mt-0 inline-block pr-3 tracking-widest'>
          {t(hasAdded ? 'editEngraving' : 'addEngraving')}
        </p>
        <div className='inline-block'>
          <p className='text-xs text-black md:font-medium'>
            {formatDisplayPrice(engravingProduct.display_price)}
          </p>
        </div>
      </div>
    </button>
  );
}
