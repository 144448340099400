'use client';
import { getDiscountValues } from 'ui';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useMemo, useState } from 'react';
import type { SiteFormatValues } from '@whoop/i18n';
import { useShallow } from 'zustand/react/shallow';
import type { PersonalizationOption } from 'lib/utils/personalizationHelper';
import { useSiteStore } from '@/store/SiteStoreProvider';

export default function Label({
  siteValues,
  option,
}: {
  option?: PersonalizationOption;
  siteValues: SiteFormatValues;
}): React.JSX.Element {
  const { t } = useTranslation('joinLandingPage');
  const { t: tMembership } = useTranslation('membershipPage');
  const { promoContent, promoInfo } = useSiteStore(
    useShallow((state) => ({
      promoContent: state.promoContent,
      promoInfo: state.promoInfo,
    })),
  );

  const promoCode = promoContent?.promo_info ?? promoInfo?.promo_code;
  const [showOriginalPrice, setShowOriginalPrice] = useState(true);

  const { displayPrice, originalDisplayPrice, isDiscounted } = useMemo(() => {
    if (!option?.product)
      return {
        displayPrice: '--',
        originalDisplayPrice: '--',
        isDiscounted: false,
      };

    return getDiscountValues(option.product, siteValues, promoCode, true);
  }, [option, promoCode]);

  const discountText = useMemo(() => {
    if (
      isDiscounted &&
      promoCode &&
      (promoCode.code === 'PREPAIDBUNDLE' ||
        promoCode.code === 'PREPAIDBUNDLEAFFILIATE')
    ) {
      setShowOriginalPrice(false);
      return tMembership('saveBundleSale', { newPrice: displayPrice });
    }
    setShowOriginalPrice(true);
    return tMembership('save', { savedAmount: option?.percent_discount });
  }, [option, promoCode]);

  return (
    <>
      <p className='whitespace-nowrap pr-2 text-sm font-bold uppercase tracking-wider'>
        {option?.handle !== 'onyx' ? (
          <span className='pr-2 font-semibold'>+</span>
        ) : null}
        {option?.label}
      </p>
      <p className='pr-2 text-sm uppercase tracking-wide'>
        {showOriginalPrice && option?.display_price ? displayPrice : null}
        {option?.included ? t('included') : null}
        <span className='text-gray-b50 pl-1 line-through'>
          {!option?.included && isDiscounted ? originalDisplayPrice : null}
        </span>
      </p>
      {isDiscounted ? (
        <p className='whitespace-nowrap pr-2 text-sm font-semibold uppercase tracking-wide text-[#67AEE6]'>
          {discountText}
        </p>
      ) : null}
    </>
  );
}
