import type { WheelEventHandler } from 'react';
import React, { useId } from 'react';
import clsx from 'clsx';
import Success from '../../icons/icons/Toast/success';

export interface IInputProps {
  id: string;
  onChange: (string, event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  onWheel?: WheelEventHandler<HTMLInputElement>;
  name?: string;
  required?: boolean;
  onClick?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  errorMessageAction?: () => void;
  helpText?: string;
  value?: string;
  type?: 'text' | 'number' | 'password' | 'file' | 'tel' | 'textarea';
  onBlur?: (string) => void;
  autoComplete?: string;
  className?: string;
  showCheckmark?: boolean;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
}
/**
 * @deprecated Use InputV2/Input instead.
 */
const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      name,
      label,
      onClick,
      onChange,
      onWheel,
      onBlur,
      hasError = false,
      errorMessage,
      errorMessageAction,
      helpText,
      required = false,
      type = 'text',
      value,
      autoComplete,
      className,
      showCheckmark = false,
      disabled = false,
      maxLength,
      placeholder,
    },
    ref,
  ) => {
    const errorId = useId();
    const helpTextId = useId();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      onChange(value, event);
    };

    const showErrorMessage = hasError && errorMessage;

    return (
      <div className={clsx('relative text-base font-normal', className)}>
        <input
          aria-describedby={`${helpTextId} ${hasError ? errorId : ''}`}
          aria-invalid={hasError}
          aria-label={label}
          autoComplete={autoComplete}
          className={`body-lg disabled:bg-gray-10 peer h-16 w-full rounded-md border-2 px-4 pt-4 placeholder-transparent focus:outline-none focus:ring-2 dark:border-white/70 dark:bg-transparent dark:text-white
            ${
              hasError
                ? 'dark:border-red-a400 border-red-500'
                : 'border-gray-300 text-black'
            }`}
          disabled={disabled}
          id={id}
          inputMode={type === 'number' ? 'numeric' : 'text'}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onClick={onClick}
          onWheel={onWheel}
          placeholder={placeholder || label}
          ref={ref}
          required={required}
          type={type}
          value={value}
        />
        <label
          className='pointer-events-none absolute left-2 top-2 ml-0.5 block px-2 text-sm transition-all duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:top-2 peer-focus:text-sm dark:text-white/70'
          htmlFor={id}
        >
          {label}
        </label>
        {showCheckmark ? (
          <Success
            className='absolute right-3 top-1/2 -translate-y-1/2 scale-75'
            color='#00F19F'
          />
        ) : null}
        {showErrorMessage ? (
          errorMessageAction ? (
            <button
              className='error dark:text-red-a400 pl-4 text-red-600'
              id={errorId}
              onClick={errorMessageAction}
              type='button'
            >
              {errorMessage}
            </button>
          ) : (
            <p
              className='error dark:text-red-a400 pl-4 text-red-600'
              id={errorId}
            >
              {errorMessage}
            </p>
          )
        ) : null}
        {helpText ? (
          <p
            aria-describedby={helpTextId}
            className='pl-4 text-gray-600 dark:text-white/70'
          >
            {helpText}
          </p>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
