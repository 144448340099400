'use client';
import { useTranslation } from '@whoop/i18n/lang/client';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import RadioSwatches from 'ui/components/RadioSwatches/RadioSwatches';
import type { RadioInputOption } from 'ui';
import type { PersonalizationOption } from 'lib/utils/personalizationHelper';
import Label from './label';

interface SwatchSelectorProps {
  name: string;
  language: Language;
  onChange: (handle: string) => void;
  onHover: (handle: string) => void;
  value: string;
  size: 'small' | 'medium' | 'large';
  options: RadioInputOption[];
  useExternalState?: boolean;
  currency: Currency;
  option: PersonalizationOption;
  region: Region;
}

/**
 * @param param0
 * @returns
 */
function SwatchSelector({
  name,
  language,
  onChange,
  onHover,
  value,
  size,
  options,
  useExternalState,
  currency,
  option,
  region,
}: SwatchSelectorProps): JSX.Element {
  const { t } = useTranslation('joinLandingPage', language);
  const defaultStrapSelected = 'onyx';

  return (
    <div className='z-20 flex flex-col gap-1 py-3 md:pt-6'>
      <p className='my-0 flex justify-center text-lg font-semibold md:hidden md:text-2xl'>
        {t('chooseWhoop4Band')}
      </p>
      <div className='flex flex-wrap justify-center pb-4 md:hidden'>
        <Label option={option} siteValues={{ currency, language, region }} />
      </div>
      <RadioSwatches
        allowNone
        collapseByDefault={false}
        defaultSelection={[defaultStrapSelected]}
        name={name}
        onChange={onChange}
        onHover={onHover}
        options={options}
        showAllOptions
        showCheckmark
        showToolTips={false}
        size={size}
        useExternalState={useExternalState}
        value={value}
      />
    </div>
  );
}

export default SwatchSelector;
