'use client';
import { useWindowSize } from '@uidotdev/usehooks';

interface ScreenSizes {
  isScreenSizeKnown: boolean;
  isMobile?: boolean;
  isMobileOrSmaller?: boolean;
  isMobileOrLarger?: boolean;
  isTablet?: boolean;
  isTabletOrSmaller?: boolean;
  isTabletOrLarger?: boolean;
  isLaptop?: boolean;
  isLaptopOrSmaller?: boolean;
  isLaptopOrLarger?: boolean;
  isDesktop?: boolean;
}

/**
 * These sizes correspond to CSS mixins.
 * Do not change these without also changing packages/ui/styles.responsive.mixin.scss
 * X-Small Mobile: 319px or smaller
 * Mobile: 320px - 600px
 * Tablet: 601px - 960px
 * Laptop: 961px - 1140px
 * Desktop: 1141px or greater
 */
export const useScreenSize = (): ScreenSizes => {
  const { width } = useWindowSize();
  if (!width) return { isScreenSizeKnown: false };
  return {
    isScreenSizeKnown: true,
    isMobile: width >= 320 && width <= 600,
    isMobileOrSmaller: width <= 600,
    isMobileOrLarger: width >= 320,
    isTablet: width >= 601 && width <= 960,
    isTabletOrSmaller: width <= 960,
    isTabletOrLarger: width >= 601,
    isLaptop: width >= 961 && width <= 1140,
    isLaptopOrSmaller: width <= 1140,
    isLaptopOrLarger: width >= 961,
    isDesktop: width >= 1141,
  };
};
