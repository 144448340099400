import { useShallow } from 'zustand/react/shallow';
import { useSiteStore } from '@/store/SiteStoreProvider';

export default function Eyebrow(): React.JSX.Element | null {
  const { siteWidePromoContent, promoContent } = useSiteStore(
    useShallow((state) => ({
      siteWidePromoContent: state.siteWidePromoContent,
      promoContent: state.promoContent,
    })),
  );

  if (
    promoContent?.partnership_name ||
    !siteWidePromoContent?.landingPageEyebrow
  )
    return null;

  return (
    <div className='hidden w-full justify-center rounded bg-black p-3 text-sm font-semibold text-white md:flex'>
      {siteWidePromoContent.landingPageEyebrow}
    </div>
  );
}
